import React, {useState, useEffect, useContext} from 'react';
import { UserContext } from '../../utils/user_context';

export const Navbar = () => {

  const { user } = useContext(UserContext);
  const [searchKey, setSearchKey] = useState();
  const [searchKey2, setSearchKey2] = useState();

  useEffect(() => {
    console.log('fsdfds');
  }, [searchKey]);

  return(
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
        <a className="navbar-brand" href="#">Navbar</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Link</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">Action</a>
                <a className="dropdown-item" href="#">Another action</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">Something else here</a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" href="#">{user.name}</a>
            </li>
          </ul>
          <form className="form-inline my-2 my-lg-0 form-custom">
            <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" defaultValue={searchKey} onChange={(event)=> setSearchKey(event.target.value)} />
            <input className="form-control mr-sm-2" type="search" placeholder="Search2" aria-label="Search2" defaultValue={searchKey2} onChange={(event)=> setSearchKey2(event.target.value)} />
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
          </form>
        </div>
      </nav>
      <div>
        You are searching for : {searchKey} + {searchKey2}
      </div>
    </div>
  );
}
