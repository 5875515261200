import React from 'react';
import Square from './square';

const CASE_CAN_WIN = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8],
  [2 ,4 ,6]
];

export default class Board extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      squares: Array(9).fill(null),
      xIsNext: true,
      hasWinner: null
    };
  }

  handleClick(i) {
    if(this.state.hasWinner != null){
      return false;
    }
    const squares = this.state.squares;
    squares[i] = this.state.xIsNext == true ? "X" : "O";
    this.setState({
      squares: squares,
      xIsNext: !this.state.xIsNext
    });
    let winner = calculateWinner(this.state.squares);
    if(winner != null){
      alert("The winner is: " + winner);
      this.setState({hasWinner: squares[i]});
    }
  }

  renderSquare(i) {
    return(
      <Square handleClick={() => this.handleClick(i)} value={this.state.squares[i]} />
    );
  }

  render() {
    let status;
    if(this.state.hasWinner != null){
      status = "The winner is: " + this.state.hasWinner;
    }else{
      status = "Next player: " + (this.state.xIsNext == true ? "X" : "O");
    }

    return(
      <div>
        <p>
          {status}
        </p>
        <div className="board-row">
          {this.renderSquare(0)}{this.renderSquare(1)}{this.renderSquare(2)}
        </div>
        <div className="board-row">
          {this.renderSquare(3)}{this.renderSquare(4)}{this.renderSquare(5)}
        </div>
        <div className="board-row">
          {this.renderSquare(6)}{this.renderSquare(7)}{this.renderSquare(8)}
        </div>
      </div>
    );
  }
}

function calculateWinner(squares) {
  for(let i=0; i < CASE_CAN_WIN.length; i++){
    let [a, b, c] = CASE_CAN_WIN[i];
    if(squares[a] && squares[a] == squares[b] && squares[a] == squares[c]){
      return squares[a];
    }
  }
  return null;
}
