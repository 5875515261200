import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Game from './components/game';
import {Navbar} from './components/headers/navbar';
import {UserProvider} from './utils/user_context';

ReactDOM.render(
  <UserProvider>
    <Navbar />
  </UserProvider>
,document.getElementById('navbar'));
ReactDOM.render(<Game />,document.getElementById('root'));
