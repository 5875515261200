import React from 'react';
import ReactDOM from 'react-dom';
import Board from './board';
import '../App.css';

export default class Game extends React.Component{
  render() {
    return(
      <div>
        <Board />
      </div>
    );
  }
}
